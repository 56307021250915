* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  height: 100vh;
  scrollbar-width: none;
  font-family: "Roboto", sans-serif;

  &::-webkit-scrollbar {
    display: none;
  }
}

$purple-main-color: #6369d1;
$white: #fff;
$green-font: #2d8384;
$black: #1d1d1f;
$light-green-bg: #d5e6e6;
$light-grey-bg: #f5f5f7;

@mixin basic-shadow {
  box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.25);
}

@mixin neumorphism-vol($color) {
  box-shadow: 0.1rem 0.1rem 0.14rem darken($color, 8),
    -0.1rem -0.1rem 0.12rem lighten($color, 8);
}

@mixin icon-container-size($size) {
  width: $size;
  height: $size;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include neumorphism-vol($purple-main-color);
}

//Typography

h1,
h2,
h3 {
  letter-spacing: 0.04rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

h3 {
  margin: 0 0 2rem 0;
  padding: 2rem 0 0;
  font-size: 1.6rem;
}

h5 {
  font-size: 1rem;
}

//General parts
body {
  color: #1d1d1f;
  font-weight: 400;
  text-align: center;
}

.gen-container {
  padding: 2rem 1rem;
  max-width: 370px;
  margin: 0 auto;
  position: relative;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

section {
  display: flex;
  align-items: center;
  padding: 3rem 0;
  min-height: 100vh;
  scroll-snap-align: start;
}

.purple-btn {
  margin-top: 0.8rem;

  background-color: $purple-main-color;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;

  border-radius: 0.7rem;
  color: rgba($color: $white, $alpha: 0.9);
  border: none;
  @include basic-shadow;

  font-size: 0.8rem;
  text-transform: uppercase;
  &:hover {
    background-color: darken($purple-main-color, 15%);

    .icon-container {
      @include neumorphism-vol(darken($purple-main-color, 15%));
    }
  }

  p {
    flex-grow: 1;
  }
}

.gen-card {
  background: $white;
  display: flex;
  flex-direction: column;
  border-radius: 0.7rem;
  @include basic-shadow;
}

.icon-container {
  @include icon-container-size(2.2rem);
}
//Overlay and Modal
.overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba($black, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal_window {
    background-color: $white;
    padding: 2rem;
    border-radius: 0.7rem;
  }
}

//Header section
header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: url("./img/bg-img.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  scroll-snap-align: start;

  a {
    text-decoration: none;
  }

  .logo {
    color: $black;

    span {
      color: $purple-main-color;
      font-style: italic;
    }
  }

  .menu-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba($white, 0.9);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    padding: 0 0 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 997;

    .hamburger {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .hamburger-line {
        width: 2.4rem;
        height: 3px;
        margin-top: 8px;
        background: $purple-main-color;
        border-radius: 1rem;
        &:first-child {
          margin-top: 0;
        }
      }

      &:hover {
        background: $light-grey-bg;

        .hamburger-line {
          background: darken($color: $purple-main-color, $amount: 15);
        }
      }
    }

    nav {
      display: none;
    }

    .nav-mobile {
      display: block;
      position: absolute;
      width: 100%;
      top: 3.6rem;
      left: 0;
      @include basic-shadow;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        background-color: $white;

        li {
          padding: 1rem auto;
          width: 100%;

          &:hover {
            background: $light-grey-bg;
          }
          a {
            display: inline-block;
            width: 100%;
            padding: 1rem 0;
            color: $black;
            text-decoration: none;

            &:hover,
            &:focus {
              color: $purple-main-color;
            }
          }
        }

        .btn-cv-container {
          align-self: center;
          .purple-btn {
            margin: 1rem auto;

            .icon-container {
              @include icon-container-size(1.8rem);
            }

            p {
              padding: 0 0.8rem;
            }

            &:hover {
              .icon-container {
                @include neumorphism-vol(darken($purple-main-color, 15%));
              }
            }
          }
        }
      }
    }
  }

  .header-content {
    h1 {
      margin-top: 1rem;
      font-size: 2.2rem;
      line-height: 1.2;

      span {
        text-transform: uppercase;
        color: $green-font;
        font-style: italic;
      }
    }

    h2 {
      margin-top: 2.6rem;
      font-family: "Roboto", sans-serif;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .purple-btn {
    align-self: center;

    p {
      margin: 0 1rem;
    }
  }
}

.skills {
  background: linear-gradient($light-green-bg, $white);

  .skills-set {
    background-color: $light-grey-bg;
    border-radius: 0.7rem;
    padding: 3rem;
    text-align: left;
    @include basic-shadow;

    .sep-skill-container {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }

      h4 {
        border-bottom: 1px solid #d2d2d2;
        padding-bottom: 0.4rem;
        font-size: 1.1rem;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
          margin-right: 1.2rem;
          margin-top: 0.6rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-left: 0.4rem;
          }
        }
      }
    }
  }
}

.portfolio {
  background: $green-font;
  background-image: url("./img/abstract-circles.svg");
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: bottom;

  height: 100vh;

  h3 {
    color: $white;
  }

  .gen-container {
    max-width: 100%;
    height: 100%;
  }

  .swiper-container {
    width: 100%;
    flex-grow: 1;

    .swiper-button-next,
    .swiper-button-prev {
      color: $white;
      top: 94%;
      transform: translate(0, -160%);
    }

    .swiper-pagination {
      bottom: 6%;
      width: 40%;
      left: 50%;
      transform: translate(-50%, -60%);
      .swiper-pagination-bullet {
        background-color: $white;
      }
    }

    h4 {
      color: $white;
      font-weight: 400;
      font-size: 1.2rem;
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .slide-container {
        position: relative;
        margin: 2rem auto;

        .slide-overlay {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba($white, 0.7);
          backdrop-filter: blur(2px);
          overflow: hidden;
          width: 94%;
          border-radius: 0.7rem;
          margin: 0 auto;
          height: 0;
          transition: 0.5s ease;

          .slide-obj,
          .slide-tech {
            display: none;
            text-align: left;
          }

          h5 {
            font-size: 1rem;
          }

          p {
            margin-top: 0.8rem;
          }

          a {
            text-decoration: none;
            background-color: $purple-main-color;
            color: $white;
            padding: 0.4rem 1.5rem;
            border-radius: 0.4rem;

            &:hover {
              background-color: darken($purple-main-color, 15);
            }
          }
        }

        &:hover .slide-overlay {
          border: 1px solid $white;
          height: 100%;
        }
      }

      img {
        width: 94%;
        height: 100%;
        margin: 0 auto;
        border-radius: 0.7rem;
        @include basic-shadow;
      }
    }

    .swiper-nav {
      position: relative;
      display: flex;
      align-items: center;
      bottom: 20%;
      width: 340px;
      margin: 5rem 0;

      .swiper-pagination {
        bottom: auto;

        .swiper-pagination-bullet {
          background-color: rgba($white, 0.7);
        }
        .swiper-pagination-bullet-active {
          background-color: $white;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        color: white;
      }
    }
  }
}

.about {
  background: $light-grey-bg;
  background-image: url("./img/dots-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;

  .about-container {
    .about-photo {
      background: url("./img/yar-g-dev.jpg");
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 260px;
      border-radius: 0.7rem 0.7rem 0 0;
    }

    .about-description {
      margin: 2rem;
      text-align: left;

      p {
        margin-top: 0.7rem;
        line-height: 1.4;
        letter-spacing: 0.02rem;
        &:first-child {
          margin: 0;
        }
      }

      ul {
        margin: 1rem 0 0 2rem;
      }
    }
  }
}

.contact {
  background: linear-gradient(135deg, $green-font, $light-green-bg);

  h3 {
    color: $white;
  }

  form {
    padding: 3rem;
    text-align: left;

    .form_err {
      color: red;
      font-size: 0.7rem;
    }

    label {
      margin-top: 0.8rem;
      margin-bottom: 0.4rem;
      font-size: 0.8rem;

      &:first-child {
        margin-top: 0;
      }
    }

    input,
    textarea {
      border: none;
      background: $light-grey-bg;
      font-size: 1rem;
      border-radius: 0.6rem;
      padding: 0.4rem;

      &:focus {
        outline: 1px solid $light-green-bg;
      }
    }

    textarea {
      resize: none;
    }
  }
}

footer {
  background-color: $black;
  color: rgba($white, 0.9);
  scroll-snap-align: start;

  svg {
    color: rgba($white, 0.7);
  }

  .social-links {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    .footer-btn-container {
      width: 3rem;
      height: 3rem;
      border-radius: 0.6rem;
      @include neumorphism-vol(#1d1d1f);

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        svg {
          color: rgba($white, 0.95);
        }
      }
    }
  }

  p {
    margin-top: 1rem;
  }
}

//Desktop + Tablet Version
@media (min-width: 768px) {
  h1,
  h2,
  h3 {
    text-align: left;
  }

  .gen-container {
    max-width: 760px;
  }

  //Header Section
  header {
    background-size: contain;

    .gen-container {
      align-items: flex-start;
    }
    .menu-bar {
      padding: 0.8rem 4rem;

      .hamburger {
        display: none;
      }

      nav {
        display: block;

        ul {
          list-style-type: none;
          display: flex;
          align-items: center;
          column-gap: 1.4rem;

          li {
            a {
              display: inline-block;
              color: $black;
              height: 100%;

              &:hover,
              &:focus {
                color: $purple-main-color;
              }
            }
          }
        }

        .purple-btn {
          margin: 0 0 0 2rem;

          .icon-container {
            @include icon-container-size(1.8rem);
          }

          &:hover {
            .icon-container {
              @include neumorphism-vol(darken($purple-main-color, 15%));
            }
          }
        }
      }
    }

    .header-content {
      max-width: 70%;
    }

    .purple-btn {
      align-self: flex-start;
    }
  }

  .skills {
    .sep-skill-list {
      margin-left: 30%;
    }
  }

  .portfolio {
    background-image: url("./img/desktop-circles.svg");
    background-size: contain;
    background-position: right;
    min-height: 100vh;

    .gen-container {
      max-width: 760px;
      height: 100%;
      padding: 0;
    }

    .slider-container {
      .slide {
        height: 50%;
      }
    }
    .swiper-container {
      width: 700px;
      height: 300px;
      flex-grow: 1;

      .swiper-button-next,
      .swiper-button-prev {
        top: 100%;
        transform: translate(0, -140%);
      }

      .swiper-pagination {
        bottom: 0%;
        transform: translate(-50%, -100%);
      }

      .swiper-slide {
        .slide-container {
          img {
            width: 94%;
          }
          .slide-overlay {
            padding: 0rem 2rem;
            align-items: flex-start;
            text-align: left;

            .slide-obj,
            .slide-tech {
              display: block;
            }
          }

          a {
            align-self: center;
          }
        }
      }

      .swiper-nav {
        width: 50%;
        bottom: 10%;
        margin: 2rem 0;
      }
    }
  }

  .about {
    background-image: url("./img/desktop-dots.svg");
    background-size: contain;
    .gen-card {
      flex-direction: row;
    }

    .about-container {
      .about-photo {
        align-self: stretch;
        height: auto;
        width: 40%;
        border-radius: 0.7rem 0 0 0.7rem;
      }

      .about-description {
        width: 60%;
      }
    }
  }

  .contact {
    form {
      min-width: 400px;
      margin: 0 auto;
    }
  }
}
